<template>
	<div class="colculate-regist pa-3">
    <v-form ref="form" lazy-validation>
		<v-simple-table class="mt-5">
			<caption class="pb-1 mb-1 text-left font-weight-bold"
				style="border-bottom: 2px solid #00BFA5;"
			>
				결제정보
			</caption>
			<tbody>

				<tr>
					<th>상품명</th>
					<td>
						<v-text-field
							v-model="item.goodsName"
							placeholder="상품명"
							hide-details = "auto"
							color="success2"
							:rules="[rules.goodsName]"
						></v-text-field>
					</td>
				</tr>
				<tr>
					<th>판매금액</th>
					<td>
						<v-text-field
							v-model="item.totAmt"
							placeholder="판매금액"
							hide-details = "auto"
							color="success2"
							:rules="[rules.totAmt]"
						></v-text-field>
					</td>
				</tr>
				<tr>
					<th>휴대폰번호</th>
					<td>
						<v-text-field
							v-model="item.buyerTel"
							placeholder="휴대폰번호"
							hide-details="auto"
							color="success2"
							:rules="[
                  val => !!val || '휴대폰 번호를 입력하세요.',
                  val => val.startsWith('010') || '휴대폰 번호가 유효하지 않습니다.',
                  val => val.length >= 12 || '휴대폰 번호를 입력하세요.',
                  val => val.length <= 13 || '휴대폰 번호를 입력하세요.',
                  val => val.length >= 12 && val.length <= 13 || '휴대폰 번호를 입력하세요.'
              ]"
              v-mask="['###-####-####']"
						></v-text-field>
					</td>
				</tr>
				<tr>
					<th>부가세</th>
					<td>
						<v-radio-group
							v-model="item.taxFlag"
							row
							class="pa-0 ma-0 mt-3"
						>
							<v-radio
								v-for="(item, index) in codes.taxFlag"
								:key="index"
								:label="item.codeName"
								:value="item.code"
								color="success2"
								hide-details
							>
							</v-radio>
						</v-radio-group>
					</td>
				</tr>
			</tbody>
		</v-simple-table>
		
		
		<v-simple-table
			class="mt-5"
		>
			<caption
				class="pb-1 mb-1 text-left font-weight-bold"
				style="border-bottom: 2px solid #00BFA5;"
			>
				결제카드 정보
			</caption>
			<thead>
				<tr>
					<td colspan="2">
						<v-radio-group
							v-model="item.cardCate"
							row
							class="pa-0 ma-0 mt-2"
						>
							<v-row>
								<v-col
									cols="2"
									class="pa-0 ma-0"
								></v-col>
								<v-col
									class="pa-0 ma-0"
								>
									<v-radio
										label="개인카드"
										value="1"
										color="success2"
										hide-details
									>
									</v-radio>
								</v-col>
								<v-col
									class="pa-0 ma-0"
								>
									<v-radio
										label="법인카드"
										value="2"
										color="success2"
										hide-details
									>
									</v-radio>
								</v-col>
								<v-col
									cols="1"
									class="pa-0 ma-0"
								></v-col>
							</v-row>
						</v-radio-group>
					</td>
				</tr>
			</thead>
			<tbody
				class="tt"
			>
				<tr>
					<td colspan="2">
            <v-row>
              <v-col>
                <v-text-field
                    v-model="item.cardNo"
                    label="카드번호"
                    placeholder="카드번호"
                    hide-details = "auto"
                    color="success2"
                    v-mask="['####-####-####-####']"
                    :rules="[rules.cardNo]"

                ></v-text-field>
              </v-col>
              <v-col>
                <v-row
                    class="pa-0 ma-0"
                >
                  <v-col
                      class="pa-0 ma-0"
                  >
                    <v-text-field
                        v-model="item.expireMm"
                        label="유효기간(월)"
                        placeholder="MM"
                        hide-details = "auto"
                        color="success2"
                        v-mask="'##'"
                        :rules="[rules.expireMm]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      class="pa-0 ma-0"
                  >
                    <v-text-field
                        v-model="item.expireYy"
                        label="유효기간(년)"
                        placeholder="YY"
                        hide-details = "auto"
                        color="success2"
                        v-mask="'##'"
                        :rules="[rules.expireYy]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
					</td>
				</tr>
				<tr v-if="user.keyinFlag == '4'">
					<td colspan="2">
						<v-row>
							<v-col>
								<v-text-field
									v-model="item.cardPassword"
									label="비밀번호"
									placeholder="앞 2자리"
									hide-details = "auto"
									color="success2"
									type="password"
									:rules="[rules.cardPassword]"
                  v-mask="'##'"
								></v-text-field>
							</v-col>
              <v-col
                  class="text-left"
              >
                <v-text-field
                    v-model="item.buyerBirth"
                    label="본인확인"
                    :placeholder="buyerBirthPlaceholderText"
                    hide-details = "auto"
                    color="success2"
                    :rules="[rules.buyerBirth]"
                    v-mask="buyerBirthMask"

                ></v-text-field>
              </v-col>
						</v-row>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<v-row>
							<v-col
								class="text-left"
							>
								<v-select
									v-model="item.installment"
									:item-text="item.text"
									:item-value="item.value"
									:items="installment"
									label="할부"
									class="d-inline-block mr-5"
									width="100%"
									hide-details
									color="success2"
									:disabled="installment_disabled"
								></v-select>
							</v-col>
              <v-col
                  v-if="user.keyinFlag == '4'"
                  class="text-left"
                  style="font-size: 0.7em;"
              >
                *개인: 주민번호 앞 6자리 <br/>
                *법인: 사업자번호 10자리
              </v-col>
						</v-row>
					</td>
				</tr>
			</tbody>
		</v-simple-table>
    </v-form>
		<div class="mt-5 mb-10">
			<v-btn
				block
				color="success2 darken-1"
				@click="save"
				:disabled="is_save ? false : true"
			>
				결제하기
			</v-btn>
		</div>
		
	</div>
</template>

<script>
	import test from "../Test.vue";

  export default{
		name: 'PaymentRegist'
		,created: function(){
			this.$emit('setProgram', this.program, this.options)
		}
    ,mounted() {
      if(this.user.keyinFlag =='2'){
        this.requestPath = '/pay/approvalCE';
      }
    }
		,props: ['user','callBack']
		,data: function(){
			return {
				program: {
					title: '신용카드 수기결제'
					,not_navigation: true
				}
        ,requestPath : '/pay/approval'
				,is_save: false
				,item: {
					buyerBirth: ''
					,buyerTel: ''
					,cardCate: '1'
					,cardNo: ''
					,cardPassword: ''
					,expireMm: ''
					,expireYy: ''
					,goodsName: ''
					,installment: '00'
					,payType: 'card'
					,taxFlag: '00'
					,totAmt: ''
				}
				,applyResult: {}
				,installment: [
					{text: '일시불',value: '00'},
          {text: '2개월',value: '02'},
          {text: '3개월',value: '03'},
          {text: '4개월',value: '04'},
          {text: '5개월',value: '05'},
          {text: '6개월',value: '06'},
          {text: '7개월',value: '07'},
          {text: '8개월',value: '08'},
          {text: '9개월',value: '09'},
          {text: '10개월',value: '10'},
          {text: '11개월',value: '11'},
          {text: '12개월',value: '12'}
				]
				,codes: {
					taxFlag: [
						{code: '00',codeName: '과세'},
            {code: '01',codeName: '비과세'}
					]
				}
				,rules:{
					goodsName: value => {
            const max = 50;

            if (!value) {
              return "상품명을 입력하세요."; // 빈값 메시지
            }
            if (value.length > max) {
              return `상품명은 최대 ${max}자까지 입력 가능합니다.`; // 길이 초과 메시지
            }

            return true; // 검증 통과 시
					}
					,totAmt: value => {
						let max = 8
            this.item.totAmt = value.replace(/[^0-9]/g, '');
            if(!value){
              return "판매금액을 입력하세요.";
            }
            if (value.length > max) {
              return `판매금액은 최대 ${max}자까지 입력 가능합니다.`; // 길이 초과 메시지
            }

            return true; // 검증 통과 시
					}
					,cardNo: value => {
						let max = 19;
            let min = 16;

            if (!value) {
              return "카드번호를 입력하세요."; // 빈값 메시지
            }
            if (value.length > max) {
              return `카드번호는 최대 ${max - 3}자까지 입력 가능합니다.`;
            }
            if (value.length < min) {
              return `카드번호는 최소 ${min - 3}자까지 입력 해야합니다.`;
            }

            return true;
					}
					,cardPassword: value => {
            if (!value) {
              return "카드 비밀번호 앞2자리를 입력해주세요.";
            }
            let min = 2;
            if(value.length < min){
              return "카드 비밀번호을 입력해주세요";
            }

            return true;
					}
					,expireMm: value => {
            if (!value) {
              return "유효기간(월)을 입력해주세요";
            }
            let min = 2;
						if(value.length < min){
							return "유효기간(월)을 입력해주세요";
						}
            if(value > 12){
              return "유효기간(월)은 12월을 넘을 수 없습니다";
            }
						return true;
					}
					,expireYy: value => {
            if (!value) {
              return "유효기간(년)을 입력해주세요";
            }
            let min = 2;
            if(value.length < min){
              return "유효기간(년)을 입력해주세요";
            }
            return true;
					}
					,buyerBirth: value => {
						let min = 6
            let cateNm = "주민";
						if(this.item.cardCate != "1"){
							min = 10
              cateNm = "사업자";
						}

            if (!value) {
              return cateNm + "번호 을 입력해주세요";
            }
						
						if(value.length < min){
							return cateNm + "번호 을" + min +"자리 입력해주세요";
						}

						return true;
					}
				}
				
			}
		}
		,computed: {
      test() {
        return test
      },
			installment_disabled: function(){
				if(this.item.totAmt >= 50000){
					return false
				}else{
					this.$set(this.item, 'installment', '00')
					return true
				}
			},
      buyerBirthPlaceholderText() {
        // cardCate 값에 따라 placeholder 변경
        return this.item.cardCate === '1'
            ? '주민번호 앞6자리'
            : '사업자번호 10자리';
      },
      buyerBirthMask() {
        return this.item.cardCate === '1' ? '######' : '##########';
      },
		}
		,methods: {
      onlyNumber(value) {
        return value.replace(/[^0-9]/g, ''); // 숫자만 남기고 나머지 문자는 제거
      },
			save: function(){
        const payload = {
          ...this.item, // item 객체 복사
          cardNo: this.onlyNumber(this.item.cardNo), // cardNo 필드만 숫자만 남도록 처리
        };

				this.$emit('axios', {
					request_type: 'post'
					,request_url: this.requestPath
					,request_params: payload
					,callBack: 'result'
					,authorize: true
					,error_message: true
				})
			}
			,cancel: function(){
				this.$emit('confirm', {
					title: '취소하시겠습니까?'
					,to: '/Home'
				})
			}
			,result: function(call){
				if(call.result){
					if(call.items.content.payResponseCode == '0001' || call.items.content.payResponseCode == '0000' || call.items.content.payResponseCode == '00'){
						this.$emit('aletMsg', 'success', '결제가 완료되었습니다.')
						this.$router.push('/Payment/Item/' + call.items.content.payResponseTranSeq)
					}else{
						this.$emit('alertMsg', 'error', call.items.content.payResponseMsg)
					}
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					console.log('watch payment : ' + call.name)
					console.log(call)
					if(call.name == 'result'){
						this.result(call)
					}
				}
			}
			,item: {
				deep: true
				,handler: function(){
          const form = this.$refs.form;
          const isValid = form.validate();
          if (isValid) {
            this.is_save = true
          }

					/*for(var col in this.item){
						if(!this.item[col] || this.item[col] == '' || this.item[col] == undefined){
              if (this.user.keyinFlag === '2' && (col === 'cardPassword' || col === 'buyerBirth')) {
                continue;
              }else{
								this.is_save = false
								return false
							}
						}else{
							this.is_save = true
						}
					}*/
					
				}
			}
		}
	}
</script>
<style>
	.colculate-regist {}
	.colculate-regist th { padding: 0 !important; text-align: left; border: none !important;}
	.colculate-regist td { padding: 5px 2px !important; height: auto; font-size: 0.8em !important; border: none !important;}
	.colculate-regist .v-input__control {min-height: 0 !important;}
	.colculate-regist .v-input__control input {font-size: 0.8em;}
	.colculate-regist .v-input__slot {margin: 0 !important;}
	
	.colculate-regist .tt label {font-size: 1em !important; color: black;}
	
	
	
	.v-select__selection {
		min-height: 0;
		width: 100%;
		max-width: 100%;
		font-size: 0.9em;
	}
	.v-select__selections input{
		min-height: 0 !important;
		height: 0 !important;
		padding: 0;
	}
	
	.v-select__slot label {
		bottom: 5px !important;
	}
</style>